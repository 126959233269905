<template>
  <PageLayout :headerText="headerText">
    <template v-slot:header>
      <div class="flex justify-end">
        <BaseButton @btn-click="onCreateSchedule">
          Create schedule
        </BaseButton>
      </div>
    </template>
    <template v-slot:main>
      <ScheduleTable class="mt-2" :schedules="schedules" />
    </template>
  </PageLayout>
</template>

<script lang="ts">
import { defineComponent } from "vue";
import PageLayout from "@/components/PageLayout.vue";
import ScheduleTable from "@/features/schedules/table/ScheduleTable.vue";
// import ScheduleForm from "@/features/schedules/ScheduleForm.vue";
// store
import useScheduleStore from "@/store/useScheduleStore";
// other
import { useRouter } from "vue-router";

export default defineComponent({
  name: "Schedules",
  components: {
    PageLayout,
    ScheduleTable
    // ScheduleForm
  },
  setup() {
    const headerText = "Schedules";

    const { schedules } = useScheduleStore({ allSchedules: true });

    const router = useRouter();

    const onCreateSchedule = () => {
      router.push({ name: "scheduleFormPage" });
    };

    return {
      headerText,
      schedules,
      onCreateSchedule
    };
  }
});
</script>
