<template>
  <TableBase>
    <template #header>
      <TableHeaderItem class="col-span-3 text-left"><span class="lg:pl-2">Name</span></TableHeaderItem>
      <TableHeaderItem class="col-span-4 text-right" :text="'Start date'" />
      <TableHeaderItem class="col-span-4 text-right" :text="'End date'" />
      <TableHeaderItem class="col-span-1" />
    </template>
    <template #body>
      <ScheduleTableRow v-for="schedule in schedules" :key="schedule.id" :schedule="schedule" />
    </template>
  </TableBase>
</template>

<script lang="ts">
import { defineComponent, PropType } from "vue";
// components
import ScheduleTableRow from "./ScheduleTableRow.vue";
import TableBase from "@/components/table/TableBase.vue";
import TableHeaderItem from "@/components/table/TableHeaderItem.vue";
// other
import { Schedule } from "@/graphql/types";

export default defineComponent({
  name: "ScheduleTable",
  components: {
    ScheduleTableRow,
    TableBase,
    TableHeaderItem
  },
  props: {
    schedules: { type: Object as PropType<Schedule>, required: true }
  },
  setup() {
    return {};
  }
});
</script>
