
import { defineComponent, PropType } from "vue";
// components
import ScheduleTableRowMenu from "./ScheduleTableRowMenu.vue";
import TableRow from "@/components/table/TableRow.vue";
import TableRowItem from "@/components/table/TableRowItem.vue";
// other
import { Schedule } from "@/graphql/types";
import { useRouter } from "vue-router";
import { cleanUuid } from "@/utils/globalHelpers";

export default defineComponent({
  name: "ScheduleTableRow",
  components: {
    ScheduleTableRowMenu,
    // ScheduleAvatar,
    TableRow,
    TableRowItem
  },
  props: {
    schedule: { type: Object as PropType<Schedule>, required: true }
  },
  setup(props) {
    const router = useRouter();

    const onShowDetails = () => {
      const scheduleId = cleanUuid(props.schedule.id);
      router.push({ path: `/schedules/${scheduleId}` });
    };

    return { onShowDetails };
  }
});
