<template>
  <TableRow class="grid-cols-12">
    <TableRowItem class="col-span-3 cursor-pointer" @click="onShowDetails">
      <div class="lg:pl-2 flex">
        <a class="flex items-center space-x-3">
          <span class="truncate hover:text-gray-600">
            {{ schedule.name }}
          </span>
        </a>
      </div>
    </TableRowItem>
    <TableRowItem class="col-span-4 text-right" :content="schedule.start_date" />
    <TableRowItem class="col-span-4 text-right" :content="schedule.end_date" />
    <TableRowItem class="col-span-1 flex justify-center"><ScheduleTableRowMenu :schedule="schedule"/></TableRowItem>
  </TableRow>
</template>

<script lang="ts">
import { defineComponent, PropType } from "vue";
// components
import ScheduleTableRowMenu from "./ScheduleTableRowMenu.vue";
import TableRow from "@/components/table/TableRow.vue";
import TableRowItem from "@/components/table/TableRowItem.vue";
// other
import { Schedule } from "@/graphql/types";
import { useRouter } from "vue-router";
import { cleanUuid } from "@/utils/globalHelpers";

export default defineComponent({
  name: "ScheduleTableRow",
  components: {
    ScheduleTableRowMenu,
    // ScheduleAvatar,
    TableRow,
    TableRowItem
  },
  props: {
    schedule: { type: Object as PropType<Schedule>, required: true }
  },
  setup(props) {
    const router = useRouter();

    const onShowDetails = () => {
      const scheduleId = cleanUuid(props.schedule.id);
      router.push({ path: `/schedules/${scheduleId}` });
    };

    return { onShowDetails };
  }
});
</script>
