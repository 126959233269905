
import { defineComponent, PropType } from "vue";
// components
import ScheduleTableRow from "./ScheduleTableRow.vue";
import TableBase from "@/components/table/TableBase.vue";
import TableHeaderItem from "@/components/table/TableHeaderItem.vue";
// other
import { Schedule } from "@/graphql/types";

export default defineComponent({
  name: "ScheduleTable",
  components: {
    ScheduleTableRow,
    TableBase,
    TableHeaderItem
  },
  props: {
    schedules: { type: Object as PropType<Schedule>, required: true }
  },
  setup() {
    return {};
  }
});
